import {
  ActionButton,
  axiosErrorToast,
  isNumber,
  Spinner,
  Table,
  TextField,
} from '@campxdev/shared'
import { Box, Card, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useImmer } from 'use-immer'
import {
  fetchBundles,
  fetchBundleSheets,
  getAccess,
  submitMarks,
} from './Service'
import {
  StyledBundleDetailsHeader,
  StyledTextContainer,
  TableContainer,
} from './Styles'

const defaultObject = {
  selectedBundle: null,
  bundles: [],
  sheets: [],
}

export function BundleMarksView() {
  const [state, setState] = useImmer(defaultObject)
  const params = useParams()

  const { data: bundles, isLoading: bundlesLoading } = useQuery(
    'evaluator-bundles',
    () => fetchBundles(),
    {
      onSuccess: (res) => {
        setState((s) => {
          s.bundles = res.Evaluation
          s.selectedBundle = res.Evaluation.find(
            (bundle) => bundle.id == params.bundleId,
          )
        })
      },
      enabled: !!params.bundleId,
    },
  )

  const { data, isLoading } = useQuery(
    ['get-bundle-sheets', params.bundleId],
    () => fetchBundleSheets(params.bundleId),
    {
      enabled: !!params.bundleId,
      onSuccess: (res) => {
        setState((s) => {
          s.sheets = res
        })
      },
    },
  )

  if (isLoading || bundlesLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner />
      </Box>
    )
  }

  return (
    <>
      {params.bundleId && (
        <Card sx={{ minHeight: 'calc(100vh - 170px)', width: '100%' }}>
          <StyledBundleDetailsHeader>
            <Typography variant="h6">{`Bundle  (ID: ${state.selectedBundle?.bundleSerialNo})`}</Typography>
            <RenderActionButton state={state} />
          </StyledBundleDetailsHeader>
          <Box
            sx={{
              margin: '20px 0px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <RenderSheets state={state} setState={setState} />
          </Box>
        </Card>
      )}
    </>
  )
}

function RenderSheets({ state, setState }) {
  if (
    getAccess(
      state?.selectedBundle?.evaluatorType,
      state?.selectedBundle?.status,
    ) &&
    state.sheets.length
  ) {
    return <MarksEditView state={state} setState={setState} />
  } else {
    return <ReadonlyMarksView state={state} setState={setState} />
  }
}

function RenderActionButton({ state }) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { mutate, isLoading: submitMarksLoading } = useMutation(submitMarks, {
    onSuccess: (res) => {
      toast.success('Marks submitted successfully')
      queryClient.invalidateQueries('evaluator-bundles')
    },
    onError: (err) => {
      axiosErrorToast(err)
    },
  })

  async function onSubmitMarks() {
    let bundleId = state.selectedBundle.id
    const sheets = state.sheets.map((item) => ({
      studExternalId: item?.id,
      evaluationMarks: item?.marks,
    }))
    mutate({ bundleId, sheets })
  }

  if (
    getAccess(
      state?.selectedBundle?.evaluatorType,
      state?.selectedBundle?.status,
    ) &&
    state.sheets.length
  ) {
    return (
      <div>
        <ActionButton
          loading={submitMarksLoading}
          size={'small'}
          onClick={onSubmitMarks}
          sx={{ width: '200px' }}
        >
          Submit marks
        </ActionButton>
      </div>
    )
  } else {
    return null
  }
}

function MarksEditView({ state, setState }) {
  const columns = [
    {
      title: 'Sl No.',
      dataIndex: 'slno',
      key: 'slno',
    },
    {
      title: 'Marks',
      dataIndex: 'marks',
      key: 'marks',
      render: (_, row, index) => (
        <>
          <StyledTextContainer disabled={false}>
            <TextField
              size="small"
              sx={{ width: '60%' }}
              inputProps={{ min: 0, max: row?.extMax }}
              value={row?.marks ?? 0}
              onChange={(e) => {
                if (!isNumber(e.target.value)) return
                setState((s) => {
                  s.sheets[index]['marks'] = e.target.value
                })
              }}
            />
            <Typography sx={{ width: '40%' }}>{`/ ${row?.extMax} `}</Typography>
          </StyledTextContainer>
          {row?.marks > row?.extMax && (
            <Typography variant="h5" color="error" sx={{ marginTop: '5px' }}>
              Invalid marks
            </Typography>
          )}
        </>
      ),
    },
  ]
  return (
    <>
      <TableContainer>
        <Table dataSource={state?.sheets ?? []} columns={columns} />
      </TableContainer>
    </>
  )
}

function ReadonlyMarksView({ state, setState }) {
  if (state?.editMarks) {
    return <MarksEditView state={state} setState={setState} />
  }

  const columns = [
    {
      title: 'Booklet serial number',
      dataIndex: 'slno',
      key: 'slno',
    },
    {
      title: 'Marks',
      dataIndex: 'marks',
      key: 'marks',
    },
  ]
  return (
    <>
      <TableContainer>
        <Table dataSource={state?.sheets ?? []} columns={columns} />
      </TableContainer>
    </>
  )
}
