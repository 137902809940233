import {
  ErrorBoundary,
  FullScreenLoader,
  Helmet,
  PermissionsStore,
} from '@campxdev/shared'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { Outlet, useNavigate } from 'react-router-dom'
import { EXAMS } from 'services'
import { EvaluatorStore } from 'shared-state/UserStore'
import AppHeader from './AppHeader'

export default function AppLayout() {
  const navigate = useNavigate()
  const { data, isLoading } = useQuery(
    'app-init',
    EXAMS.bundlesEvaluation.getPermissions,
    {
      onSuccess(data) {
        PermissionsStore.update((s) => {
          s.permissions = {
            can_dashboard_view: 1,
          } as any
        })
        EvaluatorStore.update((s) => {
          s.evaluator = data?.evaluator
        })
      },
      onError(err: AxiosError) {
        if (err?.response?.status === 401) {
          navigate('login')
        }
      },
    },
  )

  if (isLoading) return <FullScreenLoader />
  return (
    <ErrorBoundary>
      <Helmet
        user={data?.user}
        appTitle="Evaluator | CampX"
        favicon={data?.assets?.logo_square}
      />
      <AppHeader clientLogo={data?.assets?.logo} user={data?.evaluator} />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </ErrorBoundary>
  )
}
