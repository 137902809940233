import axios from '../../utils/axios'

const prefix = '/bundle-subjects'

export const bundleSubjects = {
  async fetchBundleSubjects(examSessionId: string) {
    const res = await axios.get(prefix + `?examGroupId=${examSessionId}`)
    return res.data
  },

  async fetchUnregisteredSubjects(params: {
    subjectId: number
    examGroupId: number
  }) {
    const res = await axios.get('/subject-registrations/unregistered', {
      params: params,
    })
    return res.data
  },
}
