import axios from 'axios'

const tenantKey = window.location.pathname.split('/')[1]

export const openAxios = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  headers: {
    'x-tenant-id': tenantKey,
  },
})
