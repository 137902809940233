import { ErrorBoundary } from '@campxdev/shared'
import { useQuery } from 'react-query'
import { useRoutes } from 'react-router-dom'
import { mainRoutes } from 'router/main'
// import 'antd/dist/antd.min.css'

function App() {
  const app = useRoutes(mainRoutes)
  return <ErrorBoundary>{app}</ErrorBoundary>
}

export default App
