import { withRouteWrapper, withSuspense } from '@campxdev/shared'
import PageNotFound from '@campxdev/shared/src/components/PageNotFound'
import AppLayout from 'layouts/AppLayout'
import DashboardLayout from 'layouts/DashboardLayout'
import EditorLayout from 'layouts/EditorLayout'
import OpenLinkLayout from 'layouts/OpenLinkLayout'
import PrintLayout from 'layouts/PrintLayout/PrintLayout'
import { lazy } from 'react'
import { baseRoutes } from './baseRoutes'
import { editorRoutes, scriptViewRoutes } from './editor'
import { individualRoutes } from './individualRoutes'
const LoginPage = lazy(() => import('pages/LoginPage'))

export const mainRoutes = [
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        element: <DashboardLayout />,
        children: withRouteWrapper(baseRoutes),
      },
      {
        element: <EditorLayout />,
        path: '/editor',
        children: withRouteWrapper(editorRoutes),
      },
    ],
  },
  {
    path: '/open',
    element: <OpenLinkLayout />,
    children: withRouteWrapper(scriptViewRoutes),
  },
  {
    path: '/login',
    element: withSuspense(<LoginPage />),
  },
  {
    path: '/print',
    element: <PrintLayout />,
    children: withRouteWrapper(individualRoutes),
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]
