import { axios } from '@campxdev/shared'

export const status = {
  created: 'created',
  evaluator_assigned: 'evaluator_assigned',
  marks_awarded: 'marks_awarded',
  chief_review: 'chief_review',
  scrutinizer_review: 'scrutinizer_review',
  completed: 'Completed',
}

export const BundleStatus = {
  created: 'Bundle Created',
  evaluator_assigned: 'Evaluator Assigned',
  marks_awarded: 'Marks Awarded',
  chief_review: 'Chief Review',
  scrutinizer_review: 'Scrutiniser Review',
  completed: 'Evaluation Completed',
}

export const fetchBundles = () => {
  return axios.get(`/exams/bundles-evaluation`).then((res) => res.data)
}

export const fetchBundleSheets = (bundleId) => {
  return axios
    .get(`/exams/bundles-evaluation/${bundleId}/sheets`)
    .then((res) => res.data)
}

export const getAccess = (evaluatorType, bundleStatus) => {
  if (
    (evaluatorType === 'Chief' && status.marks_awarded === bundleStatus) ||
    (evaluatorType === 'Evaluator1' &&
      status.evaluator_assigned === bundleStatus) ||
    (evaluatorType === 'Evaluator2' &&
      status.evaluator_assigned === bundleStatus) ||
    (evaluatorType === 'Scrutinizer' && status.chief_review === bundleStatus)
  ) {
    return true
  } else {
    return false
  }
}

export const submitMarks = ({ bundleId, sheets }) => {
  return axios
    .post(`/exams/bundles-evaluation/${bundleId}/marks/submit`, {
      sheets: sheets,
    })
    .then((res) => res.data)
}
