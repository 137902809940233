import React from 'react'
import styled from 'styled-components'

const StyledPageContent = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  margin-top: 24px;
`

export function PageContent(props) {
  return (
    <StyledPageContent style={props.style}>
      {props.children}
    </StyledPageContent>
  )
}
