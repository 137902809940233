import axios from '../../utils/axios'
const prefix = '/exams/bundles-evaluation'

export const evaluatorBundles = {
  async postBundleMarks(bundleId: any, sheets: string) {
    axios.post(prefix + `/${bundleId}/marks/submit`, {
      sheets: sheets,
    })
  },
}
