import axios from '../../utils/axios'

const prefix = '/auth-server/auth'

const appendUrl = (path: string[] | string) => {
  if (typeof path === 'string') {
    return `${prefix}/${path}`
  } else {
    return [prefix, ...path].join('/')
  }
}

export const evaluatorAuth = {
  async evaluatorLogin(body: { username: string; password: string }) {
    const res = await axios.post(appendUrl('evaluator-login'), body)
    return res.data
  },
}
