import {
  ErrorBoundary,
  FullScreenLoader,
  PermissionsStore,
} from '@campxdev/shared'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { Outlet, useNavigate } from 'react-router-dom'
import { EXAMS } from 'services'

export default function PrintLayout() {
  const navigate = useNavigate()
  const { data, isLoading } = useQuery(
    'app-init',
    EXAMS.bundlesEvaluation.getPermissions,
    {
      onSuccess(data) {
        PermissionsStore.update((s) => {
          s.permissions = {
            can_dashboard_view: 1,
          } as any
        })
      },
      onError(err: AxiosError) {
        if (err?.response?.status === 401) {
          navigate('login')
        }
      },
    },
  )

  if (isLoading) return <FullScreenLoader />

  return (
    <>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </>
  )
}
