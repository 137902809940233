import { openAxios } from 'utils/openAxios'

const prefix = '/exams/bundles-evaluation'

const digitalUrl = 'exams/bundles-evaluation-digital'

const appendUrl = (path: string[] | string, isDigital: boolean) => {
  if (typeof path === 'string') {
    return `${isDigital ? digitalUrl : prefix}/${path}`
  } else {
    return [isDigital ? digitalUrl : prefix, ...path].join('/')
  }
}

export const openApis = {
  async fetchStudentScript(id: string) {
    const res = await openAxios.get(appendUrl(`${id}/script`, true))
    return res.data
  },
}
