import { evaluatorAuth } from './auth-server/auth'
import { bundleSubjects } from './exams/bundle-subjects'
import { bundlesEvaluation } from './exams/bundles-evaluation'
import { evaluatorBundles } from './exams/evaluator-bundles'
import { openApis } from './exams/open-apis'

export const EXAMS = {
  evaluatorBundles,
  bundleSubjects,
  bundlesEvaluation,
  openApis,
}

export const AUTH = {
  evaluatorAuth,
}
