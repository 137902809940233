import { format, formatISO } from 'date-fns'
import { Store } from 'pullstate'

export const getUtcString = (date?: Date) => {
  const d1: Date = date ?? new Date()
  return formatISO(
    new Date(
      d1.getUTCFullYear(),
      d1.getUTCMonth(),
      d1.getUTCDate(),
      d1.getUTCHours(),
      d1.getUTCMinutes(),
      d1.getUTCSeconds(),
    ),
  )
}

interface ITimerStore {
  startTime: any
  endTime: any
  timerId: any
  sessionSeconds: number
  timer: {
    days: number
    hours: number
    minutes: number
    seconds: number
  }
}

export const timerStore = new Store<ITimerStore>({
  startTime: null,
  endTime: null,
  timerId: null,
  sessionSeconds: 0,
  timer: {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
})

export const startTimer = () => {
  const timerId = setInterval(() => {
    timerStore.update((s) => {
      s.sessionSeconds += 1
    })
  }, 1000)
  timerStore.update((s) => {
    s.timerId = timerId
    s.startTime = getUtcString(new Date())
  })
}

export const clearTimer = () => {
  timerStore.update((s) => {
    clearInterval(s.timerId)
    s.timerId = null
    s.sessionSeconds = 0
  })
}

export const TimerStore = {
  useState: (props?: any): ITimerStore => timerStore.useState(props),
  clearTimer,
  startTimer,
}
