import { Box, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { BundleStatus } from './Service'
import { StyledBundleListItem, StyledSidebarContainer } from './Styles'

export function BundlesList({ bundles }) {
  function totalSheets(bundles) {
    let tSheets = 0
    for (const bundle of bundles) {
      tSheets += bundle.studExternals
    }
    return tSheets
  }

  return (
    <>
      <StyledSidebarContainer>
        <Box className="header">
          <Typography variant="h5">{`Bundles ( ${bundles.length} )`}</Typography>
          <Typography variant="h5">{`Total papers: ${totalSheets(
            bundles,
          )}`}</Typography>
        </Box>
        <Box className="body">
          {bundles?.map((item, index) => (
            <BundleListItem key={index} data={item} index={index} />
          ))}
        </Box>
      </StyledSidebarContainer>
    </>
  )
}

export const BundleListItem = ({ data, index }) => {
  const navigate = useNavigate()
  const params = useParams()
  const handleClick = () => {
    navigate(`/manual/bundles/${data?.id}`)
  }

  return (
    <>
      <StyledBundleListItem
        isActive={data?.id == params?.bundleId}
        onClick={handleClick}
      >
        <Box className="left">
          <Typography variant="body2">{`Bundle ${index + 1} ( ID:${
            data?.bundleSerialNo
          } )`}</Typography>
          <Typography variant="subtitle2">
            Status:
            <span>{` ${BundleStatus[data?.status]}`}</span>
          </Typography>
          <Typography variant="subtitle2">
            Role:
            <span>{` ${data?.evaluatorType}`}</span>
          </Typography>
        </Box>
        <Box className="right">
          <Typography variant="subtitle2">{`Sheets: ${data?.studExternals}`}</Typography>
        </Box>
      </StyledBundleListItem>
    </>
  )
}
