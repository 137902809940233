import { lazy } from 'react'

const BundlesAssigned = lazy(() => import('./DigitalEvaluation'))
const BundleView = lazy(() => import('./DigitalEvaluation/BundleView'))
const EvaluationEditor = lazy(() => import('./EvaluationEditor'))
const ExamsView = lazy(() => import('./ExamsView'))
const StudentScriptView = lazy(() => import('./StudentScriptView'))

// Reports
const ScrutinyReport = lazy(
  () => import('./DigitalEvaluation/Reports/ScrutinyReport'),
)
const EvaluationPercentageDifferenceReport = lazy(
  () =>
    import('./DigitalEvaluation/Reports/EvaluationPercentageDifferenceReport'),
)

// Internal Exam
export * from './InternalExam'

export {
  BundleView,
  BundlesAssigned,
  EvaluationEditor,
  EvaluationPercentageDifferenceReport,
  ExamsView,
  ScrutinyReport,
  StudentScriptView,
}
