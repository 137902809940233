import { ErrorBoundary, Spinner, getUrlParams } from '@campxdev/shared'
import { Box } from '@mui/material'
import { PageContent } from 'components/PageContent'
import { useQuery } from 'react-query'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { BundlesList } from './BundlesList'
import { fetchBundles } from './Service'

function Bundles() {
  const bundleId = getUrlParams().bundleId
  const params = useParams()
  const navigate = useNavigate()
  const { data, isLoading } = useQuery(
    'evaluator-bundles',
    () => fetchBundles(),
    {
      onSuccess: (res) => {
        if (params.bundleId) {
          const currentBundle = res?.Evaluation?.find(
            (bundle) => bundle.id == params.bundleId,
          )
          if (currentBundle) {
            navigate(`/manual/bundles/${currentBundle?.id}`)
          } else {
            navigate(`/manual/bundles`)
          }
        }
      },
    },
  )

  if (isLoading) {
    return <Spinner />
  }

  return (
    <PageContent>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <BundlesList bundles={data?.Evaluation} />
        <Box sx={{ width: '100%' }}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </Box>
    </PageContent>
  )
}

export default Bundles
