import { Permission } from '@campxdev/shared'
import {
  EvaluationEditor,
  InternalExamEvaluation,
  StudentScriptView,
} from 'pages'

export const editorRoutes = [
  {
    path: 'external/:bundleId/:bookletId',
    element: <EvaluationEditor />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'internal/:bundleEvaluatorId/:barcode',
    element: <InternalExamEvaluation />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]

export const scriptViewRoutes = [
  {
    index: true,
    element: <StudentScriptView />,
  },

  {
    path: 'script/:scriptCode',
    element: <StudentScriptView />,
  },
]
