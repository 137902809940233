import { Box, BoxProps, styled } from '@mui/material'
import { NavLink } from 'react-router-dom'
interface TabsContainerProps {
  tabs: {
    label: string
    path: string
  }[]
  size?: 'small' | 'medium'
}

export const StyledTabsWrapper = styled(Box)<{ size: 'small' | 'medium' }>(
  ({ theme, size }) => ({
    background: theme.palette.secondary.light,
    height: size === 'small' ? '32px' : '48px',
    display: 'flex',
    width: '100%',
    gap: '5px',
    alignItems: 'end',
    paddingLeft: '20px',
    '& a': {
      display: 'block',
      textDecoration: 'none',
      fontSize: size === 'small' ? '14px' : '15px',
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
  }),
)

const StyledTab = styled(
  (props: BoxProps) => (
    <Box {...props}>
      {props.children}
      <span className="indicator"></span>
    </Box>
  ),
  { shouldForwardProp: (prop) => prop !== 'active' },
)<{ active: boolean }>(({ theme, active }) => ({
  padding: '5px 10px',
  paddingBottom: '0',
  transition: 'all 0.4s ease',
  '& .indicator': {
    display: 'block',
    height: '3px',
    width: '100%',
    borderRadius: '2px',
    background: 'transparent',
    marginTop: '3px',
    ...(active && {
      background: theme.palette.common.yellow,
    }),
  },
}))

export default function Tabs({ tabs, size = 'medium' }: TabsContainerProps) {
  return (
    <StyledTabsWrapper size={size}>
      {tabs?.map((item, index) => (
        <NavLink key={index} to={item?.path}>
          {({ isActive }) => (
            <StyledTab active={isActive}>{item?.label}</StyledTab>
          )}
        </NavLink>
      ))}
    </StyledTabsWrapper>
  )
}
