import { Box, Typography } from '@mui/material'
import { ReactNode, useState } from 'react'
import { avatarImage } from 'assets/images'
import { StyledUser } from './styles'
import { ExitToAppOutlined, HttpsOutlined } from '@mui/icons-material'
import logout from './logout'
import { DropDownButton } from '@campxdev/shared'
import ChangePassword from './ChangePassword'

export default function UserBox({
  username,
  profileIcon,
  actions,
}: {
  username: string
  profileIcon: string
  actions: { label: ReactNode; icon?: ReactNode; onClick: any }[]
}) {
  const [open, setOpen] = useState(false)

  const handleClose = (value: string) => {
    setOpen(false)
  }

  return (
    <>
      <DropDownButton
        anchor={({ open }) => (
          <Box minWidth={'80px'} textAlign="center" onClick={open}>
            <StyledUser>
              <img
                src={profileIcon ?? avatarImage}
                style={{
                  height: '32px',
                  width: '32px',
                  objectFit: 'contain',
                }}
                onError={(e: any) => {
                  e.target.src = avatarImage
                }}
              />
              <Typography variant="h6">{username}</Typography>
            </StyledUser>
          </Box>
        )}
        menu={[
          ...actions,

          {
            label: 'Change Password',
            actionType: 'dialog',
            icon: <HttpsOutlined />,
            content: ({ close }) => <ChangePassword close={close} />,
            contentTitle: 'Change Password',
          },
          {
            label: 'Logout',
            icon: <ExitToAppOutlined />,
            onClick: logout,
          },
        ]}
        menuProps={{
          PaperProps: { sx: { top: '64px !important' } },
        }}
      />
    </>
  )
}
